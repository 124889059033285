<template>
    <div class="bkColor">
        <headTitle :title="progressDetail.projectName + title" :routerPath="'/progressReport'"></headTitle>
        <div class="projectTitle">
            <div class="T">{{ progressDetail.projectName }}的项目进度安排</div>
            <div class="D">{{ progressDetail.userName }} 提交于 {{ progressDetail.createTime }}</div>
        </div>
        <div class="projectBody">
            <van-field v-model="progressDetail.projectName" label="项目名称" readonly autosize type="textarea" rows="1" />
            <van-field v-model="progressDetail.userName" label="汇报人" readonly />
            <van-field v-model="state" label="项目进度" readonly />
            <van-field v-model="progressDetail.description" label="进度概况" readonly />
            <van-field v-model="progressDetail.problemSolutions" label="存在问题及其解决方案" readonly autosize type="textarea" />
            <downloadFile :fileList="progressDetail.fileList" :routerPath="`/progressDetail?id=${id}`">
            </downloadFile>
        </div>
    </div>
</template>

<script>
import headTitle from "../components/headTitle.vue";

import Vue from 'vue';
import { Field } from 'vant'
Vue.use(Field)
import { getProgress } from '../api/progress'

import { pjStateText } from '../units/units'

import downloadFile from "../components/downloadFile.vue";

export default {
    name: 'progressDetails',
    components: {
        headTitle,
        downloadFile
    },
    mounted() {
        this.id = this.$route.query.id
        this.getProgressDetail()
    },
    data() {
        return {
            title: '项目进度详情',
            id: '',//进度id
            state: '',//状态显示文字
            progressDetail: {
                projectName: '',//项目编号
                userName: '',//汇报人
                projectStatus: '',//项目进度
                description: '',//项目概况
                problemSolutions: '',//存在问题及其解决方案
                fileList: [],//附件
                createTime: ''//创建时间
            },
            isReviewImg: false,//是否预览图片
            reViewImg: '',//预览的图片

        }
    },
    watch: {
        'progressDetail.projectStatus': {
            handler(newVal) {
                console.log(newVal)
                this.state = pjStateText(newVal)
            }
        }
    },
    methods: {
        getProgressDetail() {
            getProgress(this.id).then(res => {
                console.log(res)
                this.progressDetail = res.data.data
            })
        },
        //获取项目状态
        stateText(t) {
            return pjStateText(t)
        },
        reViewFile(url) {
            if (url.indexOf('.jpg') !== -1 || url.indexOf('.jpeg') !== -1 || url.indexOf('.png') !== -1) {
                this.isReviewImg = true
                this.reViewImg = url
                return
            }
            this.$router.push({
                path: "/preview",
                query: { url: url, routerPath: `/progressDetails?id=${this.id}` },
            });
        }
    }


}
</script>

<style lang="less" scoped>
.bkColor {
    width: 100vw;
    background: #f6f6f6;
    position: relative;
}

.projectTitle {
    width: 100vw;
    background: #fff;
    text-align: left;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 2vh;

    .T {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 1.5vh
    }

    .D {
        color: #797979;
        font-size: 0.5rem;
    }
}

.projectBody {
    background: #fff;
}

/deep/ .van-cell {
    flex-wrap: wrap;
    margin-bottom: 1vh;
}

/deep/ .van-cell__title {
    color: #797979;
}

/deep/ .van-field__label,
.van-cell__title {
    width: 100vw;

}

/deep/ .van-cell::after {
    border-bottom: none;
}

.fileList {
    width: 100%;
    padding: 10px 16px;
    box-sizing: border-box;
    font-size: 14px;
    color: #797979;

    .title {
        text-align: left;
    }

    .fileImg {
        width: 80px;
        height: 80px;
    }

    .fileName {
        width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>